import IndexPage from "@/components/pages/IndexPage";
import ClientsPage from "@/components/pages/ClientsPage";
import UsersPage from "@/components/pages/settings/UsersPage";
import ServicesPage from "@/components/pages/settings/ServicesPage";
import SystemPage from "@/components/pages/settings/SystemPage";
import NotFoundPage from "@/components/pages/NotFoundPage";

const routes = [
    {
        path: '/',
        component: IndexPage
    },
    // Клиенты
    {
        path: '/clients',
        component: ClientsPage
    },
    // Настройки
    {
        path: '/settings/users',
        component: UsersPage
    },
    {
        path: '/settings/services',
        component: ServicesPage
    },
    {
        path: '/settings/system',
        component: SystemPage
    },
    {
        path: '*',
        component: NotFoundPage
    }
];

export default routes;