<template>
  <div>

    <!-- ЗАГОЛОВОК СТРАНИЦЫ -->
    <div class="page-title">
      <div class="d-flex">
        <div class="flex-grow-1 align-self-end">
          <h2>
            <v-icon>tune</v-icon>
            Настройки
            <v-icon small class="arrow">chevron_right</v-icon>
            Сервисы
          </h2>
        </div>
        <div class="flex-grow-0 align-self-center mr-3"></div>
      </div>
      <v-divider class="mt-1 mb-3"></v-divider>
    </div>

    <!-- ТАБЛИЦА-СПИСОК С ДАННЫМИ -->
    <v-card class="pa-4">
      <v-tabs v-model="tab"
              class="font-weight-light"
              color="hcolor">
        <v-tab v-for="(item) in tabs"
               class="font-weight-regular"
               style="text-transform: none; color: #344767"
               :key="item.hash">
          <v-icon class="mr-1">{{ item.icon }}</v-icon>
          {{ item.title }}
        </v-tab>

        <v-tabs-items v-model="tab">

          <!-- НАСТРОЙКИ ПАНЕЛИ УПРАВЛЕНИЯ-->
          <v-tab-item>
            <div class="mt-6">
              <v-layout wrap>
                <v-flex xs12>
                  <p class="font-weight-regular">Боковое меню панели управления</p>

                  <v-layout wrap>
                    <v-flex xs12 md6 lg4>
                      <p class="mb-1">Отображение нижнего блока таблицы:</p>
                      <v-radio-group v-model="settings.clients_table_list_is_show_footer"
                                     :disabled="loading || saving"
                                     class="mt-0"
                                     hide-details>
                        <v-radio value="1" label="Отображать"/>
                        <v-radio value="0" label="Скрыть"/>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <v-alert v-if="err.length > 0"
                           type="error"
                           class="mt-3">
                    <div v-html="err"></div>
                  </v-alert>

                  <v-divider class="mt-3 mb-3"></v-divider>
                  <div class="d-flex">
                    <div class="flex-grow-1"></div>
                    <div class="flex-grow-0">
                      <v-btn color="success"
                             :disabled="loading || saving"
                             :loading="saving"
                             @click="save">
                        <v-icon>save</v-icon>
                        Сохранить
                      </v-btn>
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>

  </div>
</template>

<script>
export default {
  name: "ServicesPage",
  data: () => ({
    // Табы
    tab: 0,
    tabs: [
      {
        icon: 'groups',
        title: 'Контрагенты',
        hash: 'clients'
      },
    ],
    settings: {
      clients_table_list_is_show_footer: '-1',
    },
    loading: true,
    saving: false,
    err: '',
  }),
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$http.get('/api/settings/services/clients')
          .then((res) => {
            this.settings = res.data;
          })
          .catch((e) => {
            this.err = this.$formatter.getErr(e);
          })
          .finally(() => {
            this.loading = false;
          });
    },
    save() {
      this.err = '';
      this.saving = true;
      const data = {settings: JSON.parse(JSON.stringify(this.settings))};
      this.$http.post('/api/settings/services/clients', data)
          .then(() => {
            this.$store.commit("showSaveNotice", "Изменения настроек успешно сохранены");
            this.$store.state.settings.clients_table_list_is_show_footer = this.settings.clients_table_list_is_show_footer;
          })
          .catch((e) => {
            this.err = this.$formatter.getErr(e);
          })
          .finally(() => {
            this.saving = false;
          });
    }
  }
}
</script>