<template>
  <!-- БОКОВАЯ ПАНЕЛЬ -->
  <div :class="`left-panel ${isLeftPanelShort} ${hoverStyle} ${hidden}`"
       @mouseenter="addHover"
       @mouseleave="removeHover">

    <!-- БЛОК С ПУНКТАМИ МЕНЮ -->
    <v-list dark dense>

      <div v-for="(item,index) in menu"
           :key="index">
        <template v-if="item.type==='item'">
          <v-list-item @click="$router.push(item.link)"
                       :class="isMenuItemActive(item.link) ? 'active' : ''">
            <v-list-item-icon class="menu-item">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </template>
        <template v-else-if="item.type === 'divider'">
          <v-divider light></v-divider>
        </template>
        <template v-else-if="item.type === 'folder'">
          <v-list-group color="white--text"
                        :value="isCurrentGroupOpen(item.items)">
            <template v-slot:activator>
              <v-list-item class="pl-0 folder-title">
                <v-list-item-icon class="menu-item">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </template>
            <v-list-item v-for="(local, i) in item.items"
                         :class="isMenuItemActive(local.link) ? 'active' : ''"
                         :key="i"
                         @click="$router.push(local.link)">
              <v-list-item-icon class="menu-item">
                <v-icon>{{ local.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ local.title }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>
      </div>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "LeftPanel",
  data: () => ({
    hoverTimer: 0,
    hoverStyle: '',
    hidden: 'hidden',
  }),
  computed: {
    isLeftPanelShort() {

      if (this.$store.getters.isLeftMenuOpen) {
        return '';
      }

      return 'left-panel-short';
    },
    menu() {
      return this.$store.getters.getPanelLeftMenu;
    },
  },
  mounted() {
    const self = this;
    setTimeout(function () {
      self.hidden = '';
    }, 100);
  },
  methods: {
    addHover() {
      const self = this;
      clearTimeout(this.hoverTimer);
      this.hoverTimer = setTimeout(function () {
        self.hoverStyle = 'left-panel-hover';
      }, 300);
    },
    removeHover() {
      clearTimeout(this.hoverTimer);
      this.hoverStyle = '';
    },
    isMenuItemActive(link) {
      const currentPath = this.$route.path;

      return currentPath === link;
    },
    isCurrentGroupOpen(items) {
      const currentPath = this.$route.path;
      let regExp, link;

      if (items.length > 0) {

        for (let i = 0; i < items.length; i++) {
          link = items[i].link;
          regExp = new RegExp(`^${link}`, 'g');

          if (currentPath.match(regExp)) {
            return true;
          }
        }
      }
      return false;
    },
  }
}
</script>
