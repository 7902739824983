<template>
  <v-snackbar
      top
      right
      multi-line
      :timeout="1500"
      color="success"
      v-model="snackbar"
  >
    <div class="d-flex">
      <div class="flex-grow-0 align-self-center">
        <v-icon>check_circle</v-icon>
      </div>
      <div class="flex-grow-1 align-self-center pl-2">
        <strong v-html="successNoticeLabel"></strong>
      </div>
      <div class="flex-grow-0 align-self-center">
        <v-btn icon
               @click="snackbar = false">
          <v-icon color="white--text">close</v-icon>
        </v-btn>
      </div>
    </div>

  </v-snackbar>
</template>

<script>
export default {
  name: "SuccessNotice",
  data: () => ({
    snackbar: false
  }),
  computed: {
    successNoticeLabel() {
      return this.$store.getters.getSuccessNoticeLabel;
    }
  },
  watch: {
    '$store.getters.getSuccessNoticeState'(val) {

      if (val === true) {
        this.snackbar = true;
      }
    },
    snackbar(val) {

      if (val === false) {
        this.$store.commit('showNoticeHidden');
      }
    }
  }
}
</script>
