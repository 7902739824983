<template>
  <div>
    <v-alert type="error" text>
      <div>Страница не найдена</div>
    </v-alert>
  </div>
</template>

<script>
export default {
  name: "NotFoundPage"
}
</script>