import Axios from 'axios';
import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import router from "@/plugins/router";
import store from "@/plugins/store";
import settings from "@/plugins/settings";
import formatter from "@/plugins/helpers/formatter";
import ml from "@/plugins/ml";
import page from "@/plugins/helpers/page";

import App from '@/App.vue';

// Стили
import "@/assets/css/main.css";
import "@/assets/css/app-preloader.css";
import "@/assets/css/outer-panel.css";
import "@/assets/css/left-panel.css";
import "@/assets/css/theme1.css";

Vue.prototype.$http = Axios;
Vue.prototype.$settings = settings;
Vue.prototype.$formatter = formatter;
Vue.prototype.$page = page;

Vue.config.productionTip = false;
Vue.config.devtools = false;

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
  created () {
    Vue.use(ml, this.$store);
  }
}).$mount('#app');
