const ml = {
    store: null,
    install(Vue, store) {
        this.store = store;
        Vue.prototype.$t = this;
    },
    get(key) {
        const lang = this.store.getters.getLang;

        return this.langs[lang][key];
    },
    langs: {
        'ru-RU': {
            'AUTHORIZATION': 'Авторизация',
            'PASS': 'Пароль',
            'SIGN_IN': 'Войти',
            'REMEMBER_ME': 'Запомнить меня',
            'LABEL_FIELD_IS_REQUIRED': 'Поле является обязательным',
            'LABEL_AUTH_SUCCESS': 'Вы успешно авторизовались в системе',
            'FOOTER_DEFAULT_COPYRIGHT': '«Hybrid Technologies» - Многоцелевая система для управления бизнесом',
            'MINIMUM_8_CHARACTERS': 'Минимум 8 символов',
            'LABEL_ERROR': 'Ошибка',
            'ERR_FAILED_TO_GET_SECTOR_DATA': 'Не удалось получить данные сектора',
            'FAILED_TO_GET_HOST_LANGUAGE_PACK_INFORMATION': 'Не удалось получить данные о языковом пакете хоста',
            'LOGOUT': 'Выйти',
        },
        'en-US': {
            'AUTHORIZATION': 'Authorization',
            'PASS': 'Password',
            'SIGN_IN': 'Sign In',
            'REMEMBER_ME': 'Remember me',
            'LABEL_FIELD_IS_REQUIRED': 'Field is required',
            'LABEL_AUTH_SUCCESS': 'You have successfully authorized in system',
            'FOOTER_DEFAULT_COPYRIGHT': '«Hybrid Technologies» - Multi-purpose business management system',
            'MINIMUM_8_CHARACTERS': 'At least 8 characters needs',
            'LABEL_ERROR': 'Error',
            'ERR_FAILED_TO_GET_SECTOR_DATA': 'Failed to get sector data',
            'FAILED_TO_GET_HOST_LANGUAGE_PACK_INFORMATION': 'Failed to get host language pack information',
            'LOGOUT': 'Logout',
        }
    }
};

export default ml;
