<template>
  <div>

    <!-- ЗАГОЛОВОК СТРАНИЦЫ -->
    <div class="page-title">
      <div class="d-flex">
        <div class="flex-grow-1 align-self-end">
          <h2>
            <v-icon>home</v-icon>
            Главная
          </h2>
        </div>
        <div class="flex-grow-0 align-self-center mr-3"></div>
      </div>
      <v-divider class="mt-1 mb-3"></v-divider>
    </div>

  </div>
</template>

<script>
export default {
  name: "IndexPage"
}
</script>