<template>
  <v-main class="outer-panel">
    <v-app-bar fixed dark app :class="`header-block ${hidden}`">
      <v-card-title class="header-title">
        <img src="/img/h-logo.png"
             alt=""
             class="mr-3"
             style="max-width: 32px;"/>
        <span class="d-block" style="margin-top: 3px;">HYBROTECH SYSTEMS</span>
        <v-spacer></v-spacer>
        <v-menu right bottom class="menu-right">
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex"
                 v-bind="attrs"
                 v-on="on">
              <img :src="getLang()"
                   style="max-width: 24px;"
                   alt="Русский"
              />
              <v-icon class="ml-1">expand_more</v-icon>
            </div>
          </template>
          <v-list dense>
            <v-list-item v-for="(langItem, k) in langsSupported"
                         :disabled="langItem.code === currentLang"
                         @click="setLang(langItem.code)"
                         :key="k">
              <img :src="`/img/lang/${langItem.val}.png`"
                   style="width: 24px;"
                   :alt="langItem.val"
              />
              <div class="ml-3">{{ getLangLabel(langItem.code) }}</div>
              <div v-if="langItem.code === currentLang">
                <v-icon>chevron_left</v-icon>
              </div>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
    </v-app-bar>

    <v-container class="h-100 pl-3 pl-md-5 pr-3 pr-md-5">
      <v-layout class="align-center justify-center h-100">
        <v-flex xs12 md7 lg5>
          <v-card :class="`border-radius-xl d-flex flex-column form-block ${hidden}`"
                  style="background: transparent !important;">

            <v-card-title class="form-block-header white--text">
              <v-icon class="white--text mr-3">warning</v-icon>
              {{ $t.get('LABEL_ERROR') }}
            </v-card-title>

            <div class="card-padding mt-0" style="background: #fff;">

              <v-alert type="error" text class="ma-0">
                {{ $store.state.app.err }}
              </v-alert>

            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-footer fixed :class="`footer-block ${hidden} footer-copyright w-100 d-block`">
      <p class="text-center mb-0">
        © {{ (new Date().getFullYear()) }} {{ $t.get('FOOTER_DEFAULT_COPYRIGHT')}}
      </p>
    </v-footer>
  </v-main>
</template>

<script>
export default {
  name: "AppErr",
  data: () => ({
    hidden: 'hidden',
  }),
  created() {
    this.$page.setTitle(this.$t.get('LABEL_ERROR'));
  },
  computed: {
    currentLang() {
      return this.$store.getters.getLang;
    },
    langsSupported() {
      const langsMatches = this.$settings.LANGS_DOM_MATCHES;
      let result = [];

      for (let key in langsMatches) {
        result.push({
          code: key,
          val: langsMatches[key],
        });
      }

      return result;
    }
  },
  mounted() {
    const self = this;
    setTimeout(function () {
      self.hidden = '';
    }, 300);
  },
  methods: {
    getLang(lang = '') {

      if (lang === '') {
        lang = this.currentLang;
      }
      const langsDomMatches = this.$settings.LANGS_DOM_MATCHES;

      if (lang in langsDomMatches) {
        return `/img/lang/${langsDomMatches[lang]}.png`;
      }

      return '';
    },
    getLangLabel(code) {
      const langsLabels = this.$settings.LANGS_LABELS;

      if (code in langsLabels) {
        return langsLabels[code];
      }

      return ' - ';
    },
    setLang(code) {
      this.$store.dispatch('setLang', code);
    }
  }
}
</script>