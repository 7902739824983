<template>
  <div class="mt-6">
    <v-layout wrap>
      <v-flex xs12>
        <p class="font-weight-regular">Боковое меню панели управления</p>

        <v-layout wrap>
          <v-flex xs12 md6 lg4>
            <p class="mb-1">Запоминать позицию бокового меню:</p>
            <v-radio-group v-model="settings.cp_left_panel_save_state"
                           :disabled="loading || saving"
                           class="mt-0"
                           hide-details>
              <v-radio value="settings"
                       :disabled="true"
                       label="В настройках пользователя на сервере"
              />
              <v-radio value="cookies" label="В файлах cookies браузера"/>
              <v-radio value="no" label="Не запоминать"/>
            </v-radio-group>
          </v-flex>
          <v-flex xs12 md6 lg4>
            <p class="mb-1">Изначальное отображение:</p>
            <v-radio-group v-model="settings.cp_left_panel_init_is_short"
                           :disabled="loading || saving"
                           class="mt-0"
                           hide-details>
              <v-radio value="0" label="В развернутом виде"/>
              <v-radio value="1" label="В свернутом виде"/>
            </v-radio-group>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs12>
        <v-alert v-if="err.length > 0"
                 type="error"
                 class="mt-3">
          <div v-html="err"></div>
        </v-alert>

        <v-divider class="mt-3 mb-3"></v-divider>
        <div class="d-flex">
          <div class="flex-grow-1"></div>
          <div class="flex-grow-0">
            <v-btn color="success"
                   :disabled="loading || saving"
                   :loading="saving"
                   @click="save">
              <v-icon>save</v-icon>
              Сохранить
            </v-btn>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "ControlPanel",
  data: () => ({
    settings: {
      cp_left_panel_init_is_short: -1,
      cp_left_panel_save_state: '',
    },
    loading: true,
    saving: false,
    err: '',
  }),
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$http.get('/api/settings/system/cp')
          .then((res) => {
            this.settings = res.data;
          })
          .catch((e) => {
            this.err = this.$formatter.getErr(e);
          })
          .finally(() => {
            this.loading = false;
          });
    },
    save() {
      this.err = '';
      this.saving = true;
      const data = {settings: JSON.parse(JSON.stringify(this.settings))};
      this.$http.post('/api/settings/system/cp', data)
          .then(() => {
            this.$store.commit("showSaveNotice", "Изменения настроек успешно сохранены");
            this.$store.state.settings.cp_left_panel_init_is_short = this.settings.cp_left_panel_init_is_short;
            this.$store.state.settings.cp_left_panel_save_state = this.settings.cp_left_panel_save_state;
          })
          .catch((e) => {
            this.err = this.$formatter.getErr(e);
          })
          .finally(() => {
            this.saving = false;
          });
    }
  }
}
</script>