import Vue from 'vue';
import cookies from "@/plugins/cookies";
import settings from "@/plugins/settings";
import ml from "@/plugins/ml";

const InitSystem = {
    // Доступ к хранилищу
    ctx: null,
    // Точка входа инициализации системы
    async handle(ctx) {
        this.ctx = ctx;
        // Определяем локальные параметры: хост навигации и язык браузера
        this.setLocalParams();
        // Запускаем таймер прелоадера
        ctx.commit("setAppPreloaderTimer", true);

        // Запускаем цепочку инициализации
        try {
            // Получаем данные о кластере
            await this.navigateSectorPromise();
            // Устанавливаем мета-атрибут языка HTML страницы
            this.setHtmlMetaLang();
            // Устанавливаем токены из кук (если есть) ВАЖНО сделать это до запроса state
            this.setTokensFromCookies();
            // Устанавливаем заголовки запросов к кластеру
            this.setSectorHeaders();
            // Устанавливаем обработчик обновления токена
            this.setTokenRefreshHandler();
            // Проверяем статус авторизации (при наличии токенов)
            await this.checkAuthState();

            // Если пользователь авторизован
            if (ctx.getters.isUserAuthorised) {
                await this.getInitPanelParams();
            } else {
                // Если гость
                await this.getInitAuthParams();
            }
        } catch (e) {
            console.log(e);
            ctx.commit('setAppErr', e);
        }
        // Останавливаем таймер прелоадера
        ctx.commit("setAppPreloaderTimer", false);
        // Устанавливаем флаг загрузки приложения
        ctx.commit("setAppLoading", false);

    },
    reInit(ctx) {
        const self = this;
        this.ctx = ctx;
        ctx.commit('setAppPreloaderTimer', true);
        setTimeout(function () {
            ctx.commit('setAppLoading', true);
            self.handle(ctx);
        }, settings.APP_PRELOADER_DURATION);

    },
    // Определяем локальные параметры: хост навигации и язык браузера
    setLocalParams() {
        const ctx = this.ctx;
        // Задаем параметры хоста
        ctx.commit('setHost', {
            key: 'navigate',
            protocol: window.location.protocol,
            hostname: window.location.hostname,
            port: window.location.port,
        });
        // Задаем язык браузера (до запросов к платформе)
        const langBrowser = navigator.language || navigator.userLanguage;

        // Если язык поддерживается системой - устанавливаем
        if (settings.LANGS_SUPPORTED.indexOf(langBrowser) !== -1) {
            ctx.commit('setLang', {
                key: 'browser',
                lang: langBrowser
            });
        }
        // Если есть кука с языком - задаем язык принудительно
        const langForced = cookies.getCookie('lang');

        if (langForced && settings.LANGS_SUPPORTED.indexOf(langForced) !== -1) {
            ctx.commit('setLang', {
                key: 'forced',
                lang: langForced
            });
        }
        // Указываем язык
        Vue.prototype["$http"].defaults.headers.common['X-Lang'] = ctx.getters.getLang;
    },
    // Получаем данные о кластере
    navigateSectorPromise() {
        return new Promise((resolve, reject) => {
            let url = this.ctx.getters.getNavigateServerUrl + '/navigate';
            Vue.prototype.$http.get(url)
                .then((res) => {
                    const ctx = this.ctx;
                    const data = res.data;

                    // Проверка корректности данных кластера
                    if ( typeof data !== 'object'
                        || (!('sector' in data) || typeof data.sector !== 'object')
                        || (!('protocol' in data.sector) || !data.sector.protocol)
                        || (!('hostname' in data.sector) || !data.sector.hostname)
                        || (!('port' in data.sector) || !data.sector.port)
                    ) {
                        reject(ml.get('ERR_FAILED_TO_GET_SECTOR_DATA'));
                    }

                    // Проверка корректности языка хоста
                    if (!('lang' in data) || !data.lang) {
                        reject('FAILED_TO_GET_HOST_LANGUAGE_PACK_INFORMATION');
                    }
                    ctx.commit('setHost', {
                        key: 'sector',
                        protocol: data.sector.protocol,
                        hostname: data.sector.hostname,
                        port: data.sector.port,
                    });
                    ctx.commit('setLang', {
                        key: 'host',
                        lang: data.lang,
                    });
                    resolve();
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    // Устанавливаем мета-атрибут языка HTML страницы
    setHtmlMetaLang() {
        const nodeHtml = document.getElementsByTagName("html")[0];
        const lang = settings.LANGS_DOM_MATCHES[this.ctx.getters.getLang];
        nodeHtml.setAttribute("lang", lang);
    },
    // Устанавливаем токены из кук (если есть)
    setTokensFromCookies() {
        const accessToken = cookies.getCookie('accessToken');
        const refreshToken = cookies.getCookie('refreshToken');
        this.ctx.commit('setAuthState', {
            accessToken: accessToken,
            refreshToken: refreshToken,
            isAuthorized: false,
        });
    },
    // Устанавливаем заголовки запросов к кластеру
    setSectorHeaders() {
        const ctx = this.ctx;
        const app = ctx.state.app;
        let sectorPort = '';

        // Если порт нестандартный - указываем его в базовом URL
        if (['80', '443'].indexOf(String(app.hosts.sector.port)) === -1) {
            sectorPort = `:${app.hosts.sector.port}`;
        }
        // Указываем кластер и хост клиента
        Vue.prototype["$http"].defaults.headers.common['X-Host'] = app.hosts.navigate.hostname;
        Vue.prototype["$http"].defaults.baseURL = `${app.hosts.sector.protocol}//${app.hosts.sector.hostname}${sectorPort}`;
        // Указываем Access токен
        Vue.prototype["$http"].defaults.headers.common['X-Api-Key'] = ctx.getters.userAccessToken;
        // Указываем язык
        Vue.prototype["$http"].defaults.headers.common['X-Lang'] = ctx.getters.getLang;
    },
    // Устанавливаем обработчик обновления токена
    setTokenRefreshHandler() {
        const ctx = this.ctx;
        Vue.prototype.$http.interceptors.response.use(response => response, error => {
            const status = error.response ? error.response.status : null

            if (status === 401) {
                const refreshToken = ctx.state.user.refreshToken;

                if (refreshToken) {
                    const url = '/api/auth/refresh';
                    return Vue.prototype.$http.post(url, {refresh_token: refreshToken})
                        .then((res) => {
                            cookies.setCookie('refreshToken', res.data.refresh_token);
                            cookies.setCookie('accessToken', res.data.access_token);
                            ctx.state.user.refreshToken = res.data.refresh_token;
                            ctx.state.user.accessToken = res.data.access_token;
                            // Указываем Access токен
                            Vue.prototype["$http"].defaults.headers.common['X-Api-Key'] = ctx.getters.userAccessToken;
                            error.config.headers['X-Api-Key'] = res.data.access_token;

                            return Vue.prototype.$http.request(error.config);
                        })
                        .catch((err) => {
                            const status = err.response ? err.response.status : null;

                            if (status === 406) {
                                ctx.commit('setAuthState', {
                                    isAuthorized: false,
                                    refreshToken: null,
                                    accessToken: null,
                                });
                            } else {
                                return Promise.reject(err);
                            }
                        });
                }
                // Если нет refreshToken - ставим статус гостя
                ctx.commit('setAuthState', {
                    isAuthorized: false,
                    refreshToken: null,
                    accessToken: null,
                });
            }

            return Promise.reject(error);
        });
    },
    // Проверяем статус авторизации (при наличии токенов)
    checkAuthState() {
        return new Promise((resolve) => {
            const ctx = this.ctx;
            const refreshToken = ctx.state.user.refreshToken;

            // Если нет Refresh токена, пользователь НЕ авторизован
            if (!refreshToken) {
                ctx.state.user.isAuthorized = false;

                resolve();
            }
            Vue.prototype.$http.get('/api/auth/state')
                .then((res) => {

                    if (typeof res !== 'undefined' && res) {
                        ctx.state.user.isAuthorized = true;
                    }
                    resolve();
                })
                .catch(() => {
                    ctx.state.user.isAuthorized = false;
                    resolve();
                });
        });
    },
    // Запрос параметров инициализации формы входа
    getInitAuthParams() {
        return new Promise((resolve) => {
            Vue.prototype.$http.get('/api/auth/init')
                .then((res) => {
                    const data = res.data;

                    // Если есть кастомное лого
                    if ('isCustomLogo' in data.title && data.title.isCustomLogo === true) {
                        this.ctx.state.auth.title.isCustomLogo = true;
                        this.ctx.state.auth.title.customLogo = data.title.customLogo;
                    } else {
                        this.ctx.state.auth.title.isCustomLogo = false;
                        this.ctx.state.auth.title.customLogo = '';
                    }

                    // Если есть кастомный заголовок
                    if ('isCustomLabel' in data.title && data.title.isCustomLabel === true) {
                        this.ctx.state.auth.title.isCustomLabel = true;
                        this.ctx.state.auth.title.customLabel = data.title.customLabel;
                    } else {
                        this.ctx.state.auth.title.isCustomLabel = false;
                        this.ctx.state.auth.title.customLabel = '';
                    }

                    // Если есть кастомное фоновое изображение
                    if ('isCustomBgImage' in data && data.isCustomBgImage === true) {
                        this.ctx.state.auth.isCustomBgImage = true;
                        this.ctx.state.auth.customBgImage = data.customBgImage;
                    } else {
                        this.ctx.state.auth.isCustomBgImage = false;
                        this.ctx.state.auth.customBgImage = '';
                    }
                    resolve();
                });
        });
    },
    // Запрос параметров инициализации панели управления
    getInitPanelParams() {
        return new Promise((resolve) => {
            Vue.prototype.$http.get('/api/init')
                .then((res) => {
                    const data = res.data;

                    // Пользователь
                    this.ctx.state.user.id = data.user.id;
                    this.ctx.state.user.username = data.user.username;
                    this.ctx.state.user.avatar = data.user.avatar;
                    this.ctx.state.user.last_name = data.user.last_name;
                    this.ctx.state.user.first_name = data.user.first_name;
                    this.ctx.state.user.second_name = data.user.second_name;

                    // Если есть кастомное лого
                    if ('isCustomLogo' in data.panel.title && data.panel.title.isCustomLogo === true) {
                        this.ctx.state.panel.title.isCustomLogo = true;
                        this.ctx.state.panel.title.customLogo = data.panel.title.customLogo;
                    } else {
                        this.ctx.state.panel.title.isCustomLogo = false;
                        this.ctx.state.panel.title.customLogo = '';
                    }

                    // Если есть кастомный заголовок
                    if ('isCustomLabel' in data.panel.title && data.panel.title.isCustomLabel === true) {
                        this.ctx.state.panel.title.isCustomLabel = true;
                        this.ctx.state.panel.title.customLabel = data.panel.title.customLabel;
                    } else {
                        this.ctx.state.panel.title.isCustomLabel = false;
                        this.ctx.state.panel.title.customLabel = '';
                    }

                    // Если есть кастомное фоновое изображение
                    if ('isCustomBgImage' in data.panel && data.panel.isCustomBgImage === true) {
                        this.ctx.state.panel.isCustomBgImage = true;
                        this.ctx.state.panel.customBgImage = data.panel.customBgImage;
                    } else {
                        this.ctx.state.panel.isCustomBgImage = false;
                        this.ctx.state.panel.customBgImage = '';
                    }

                    // Левое меню
                    if ('leftMenu' in data.panel && data.panel.leftMenu.length > 0) {
                        this.ctx.state.panel.leftMenu = data.panel.leftMenu;
                    } else {
                        this.ctx.state.panel.leftMenu = [];
                    }

                    if ('settings' in data) {
                        this.ctx.state.settings = data.settings;

                        // Позиция бокового меню изначально
                        this.ctx.state.panel.isLeftMenuOpen = !(
                            'cp_left_panel_init_is_short' in data.settings
                            && data.settings.cp_left_panel_init_is_short === '1'
                        );

                        // Если нужно смотреть куку
                        if (
                            'cp_left_panel_save_state' in data.settings
                            && data.settings.cp_left_panel_save_state === 'cookies'
                        ) {
                            let cookieVal = cookies.getCookie('cp_left_panel_init_is_short');

                            if (cookieVal) {
                                this.ctx.state.panel.isLeftMenuOpen = (cookieVal === '1');
                            }
                        }
                    }
                    resolve();
                });
        });
    },
    async reInitAuth(ctx) {
        // Запускаем таймер задержки прелоадера
        ctx.commit("setAppPreloaderTimer", true);

        // Запускаем цепочку авторизации
        try {
            // Проверяем статус авторизации (при наличии токенов)
            await this.checkAuthState();

            // Если пользователь авторизован
            if (ctx.getters.isUserAuthorised) {
                await this.getInitPanelParams();
            } else {
                // Если гость
                await this.getInitAuthParams();
            }
        } catch (e) {
            console.log('end');
            this.handleErr(e);
        }
        // Выключаем прелоадер
        ctx.commit("setAppPreloaderTimer", false);
        // Ставим флаг завершения инициализации системы
        this.ctx.state.app.loading = false;
        // Убираем панель прелоадера
        this.ctx.state.app.preloader.isShowPanel = false;
    },
};

export default InitSystem;