import Vue from 'vue';

const formatter = {
    // Получить ошибку
    getErr(e) {
        let err = '';

        if (typeof e === 'object') {

            if ('response' in e && 'data' in e.response) {

                if (typeof e.response.data === 'object' && 'message' in e.response.data) {
                    err = e.response.data.message;
                } else if (typeof e.response.data === 'string') {
                    err = e.response.data;
                }
            } else if ('responseText' in e && typeof e.responseText === 'string') {
                err = e.responseText;
            } else {
                err = JSON.stringify(e);
            }
        } else if (typeof e === 'string' && e.length > 0) {
            err = e;
        }

        return err;
    },
    getFormattedMac(val) {
        val = String(val);

        if (val.length < 1) {
            return '';
        }

        return val.replace(/(.{2})/g,"$1:").slice(0,-1).toUpperCase();
    },
    getFormattedPrice(val) {
        let result = '0.00';

        if (val && String(val).length > 0) {
            val = Math.round(val * 100) / 100;
            val = val.toFixed(2);
            result = String(val).replace(/\d(?=(\d{3})+\.)/g, '$& ');
        }

        return result;
    },
    getFormattedNumber(val) {
        let result = '';

        if (val && String(val).length > 0) {
            result = String(val)
                .split('')
                .reverse()
                .join('')
                .match(/\d{0,3}/g)
                .join(' ')
                .split('')
                .reverse()
                .join('')
                .trim();
        }

        return result;
    },
    getString(val) {

        if (!val) {
            val = '';
        }

        return val;
    },
    getDateTimeFromIso(val) {
        return String(val).substr(0, 19);
    },
    getDateTimeFromUnix(val) {

        if (!val || Number(val) < 1) {

            return ' — ';
        }
        let date;
        let year;
        let month;
        let day;
        let result;
        let hour;
        let minutes;
        let secs;
        val = Number(val);
        date = new Date(val * 1000);
        year = date.getFullYear();
        month = "0" + (date.getMonth() + 1);
        day = "0" + date.getDate();
        hour = date.getHours();
        minutes = date.getMinutes();
        secs = date.getSeconds();

        result = day.substr(-2)
            + '.' + month.substr(-2)
            + '.' + year
            + ' ' + hour
            + ':' + minutes
            + ':' + secs;

        return result;
    },
    getShortText(text, length = 64) {
        text = text.replace(/<\/?[^>]+(>|$)/g, "");

        if (text.length > length) {
            return text.substr(0, length) + '...';
        }

        return text;
    },
};
Vue.use(formatter);

export default formatter;