<template>
  <v-app>

    <AppPreloader/>

    <AppErr v-if="isErr"/>

    <AuthPanel v-if="isGuest"/>

    <ControlPanel v-if="isUser"/>

  </v-app>
</template>

<script>
import AppPreloader from "@/components/AppPreloader";
import AppErr from "@/components/AppErr";
import AuthPanel from "@/components/AuthPanel";
import ControlPanel from "@/components/ControlPanel";

export default {
  name: 'App',
  components: {
    AppErr,
    AppPreloader,
    ControlPanel,
    AuthPanel
  },
  computed: {
    isGuest() {
      const isAppLoading = this.$store.getters.isAppLoading;
      const isUserAuthorised = this.$store.getters.isUserAuthorised;
      const isAppErr = this.$store.getters.getAppErr.length > 0;

      return !isAppLoading && !isUserAuthorised && !isAppErr;
    },
    isUser() {
      const isAppLoading = this.$store.getters.isAppLoading;
      const isUserAuthorised = this.$store.getters.isUserAuthorised;
      const isAppErr = this.$store.getters.getAppErr.length > 0;

      return !isAppLoading && isUserAuthorised && !isAppErr;
    },
    isErr() {
      const isAppLoading = this.$store.getters.isAppLoading;
      const isAppErr = this.$store.getters.getAppErr.length > 0;

      return !isAppLoading && isAppErr;
    }
  },
  created() {
    this.$store.dispatch('initSystem');
  }
};
</script>
