<template>
  <!-- ОКНО ПРЕЛОАДЕРА ПРИЛОЖЕНИЯ -->
  <div :class="`app-preloader ${appPreloaderClass}`">

    <!-- ЗНАЧОК ПРЕЛОАДЕРА -->
    <div v-if="isShowProgress">
      <VProgressCircular
          indeterminate
          :size="50"
          color="primary"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "AppPreloader",
  computed: {
    appPreloaderClass() {
      return this.$store.getters.getAppPreloaderIsShow ? '' : 'hidden';
    },
    isShowProgress() {
      return this.$store.getters.getAppPreloaderIsShowProgress;
    }
  }
}
</script>