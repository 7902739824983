import Vue from 'vue';

const cookies = {
    getCookie(cookieName) {
        let results = document.cookie.match('(^|;) ?' + cookieName + '=([^;]*)(;|$)');

        if (results) {

            return (unescape(results[2]));
        }

        return null;
    },
    setCookie(key, value, options = {}) {
        options = {
            path: '/',
            ...options
        };

        if (options.expires instanceof Date) {
            options.expires = options.expires.toUTCString();
        }
        let updatedCookie = encodeURIComponent(key) + "=" + encodeURIComponent(value);

        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;
            let optionValue = options[optionKey];

            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }
        document.cookie = updatedCookie;
    },
    setCookiePersistent(key, value) {
        let cookieOptions = {};
        let d = new Date();
        d.setTime(d.getTime() + (365*24*60*60*1000));
        cookieOptions.expires = d;
        this.setCookie(key, value, cookieOptions);
    },
    delCookie(cookieName) {
        document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
    },
    install: () => {
        Object.defineProperty(Vue.prototype, 'cookies', {
            get() {
                return cookies;
            },
        });
    },
};
Vue.use(cookies);

export default cookies;