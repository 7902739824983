<template>
  <v-main :class="`control-panel`"
          style="height: 100vh;">
    <vuescroll :ops="scrollParams">

      <TopPanel/>

      <LeftPanel/>

      <!-- ОСНОВНАЯ ЧАСТЬ СТРАНИЦЫ-->
      <div :class="`pa-6 main-panel theme1 ${isLeftPanelShort} ${hidden}`">
        <transition
            name="fade"
            mode="out-in">
          <router-view></router-view>
        </transition>
      </div>
    </vuescroll>

    <!-- УВЕДОМЛЕНИЕ ОБ УСПЕШНОЙ ОПЕРАЦИИ -->
    <SuccessNotice/>

  </v-main>
</template>

<script>
import TopPanel from "@/components/control_panel/TopPanel";
import LeftPanel from "@/components/control_panel/LeftPanel";
import vuescroll from 'vuescroll';
import SuccessNotice from "@/components/control_panel/SuccessNotice";

export default {
  name: "ControlPanel",
  data: () => ({
    scrollParams: {
      vuescroll: {
        checkShiftKey: true,
        locking: false
      },
      scrollButton: {
        enable: true,
        background: 'rgba(0, 0, 0, 0.4)',
        opacity: 0,
        step: 180,
        mousedownStep: 30
      },
      scrollPanel: {
        scrollingX: false,
        scrollingY: true,
      },
      bar: {
        onlyShowBarOnScroll: false,
        keepShow: false,
        opacity: '0.5',
        background: 'rgba(0, 0, 0, 0.3)'
      }
    },
    hidden: 'hidden',
  }),
  components: {SuccessNotice, LeftPanel, TopPanel, vuescroll},
  created() {
    this.$page.setTitle('Панель управления');
  },
  mounted() {
    this.initPanel();

  },
  computed: {
    isLeftPanelShort() {

      if (this.$store.getters.isLeftMenuOpen) {
        return 'main-page-with-menu';
      }

      return '';
    }
  },
  methods: {
    initPanel() {
      const self = this;
      setTimeout(function () {
        self.hidden = '';
      }, 100);
    }
  }
}
</script>
