<template>
  <div class="theme1">

    <template v-if="!loading && err.length === 0">

      <!-- ЗАГОЛОВОК СТРАНИЦЫ -->
      <div class="page-title">
        <div class="d-flex">
          <div class="flex-grow-1 align-self-end">
            <h2>
              <v-icon>groups</v-icon>
              Контрагенты
            </h2>
          </div>
          <div class="flex-grow-0 align-self-center mr-3">
            <v-icon class="btn-icon grey-icon"
                    @click="$router.push('/settings/services#clients')">
              settings
            </v-icon>
          </div>
        </div>
        <v-divider class="mt-1 mb-3"></v-divider>
      </div>

      <!-- ТАБЛИЦА-СПИСОК С ДАННЫМИ -->
      <v-card class="pa-4">
        <vuescroll :ops="scrollParams" class="horizontal-scroll">

          <table class="table">
            <thead>
            <tr>
              <th class="td-min-width pa-1">
                <v-menu right bottom
                        offset-y
                        class=""
                        :nudge-bottom="10"
                        :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs"
                            v-on="on"
                            small>settings
                    </v-icon>
                  </template>
                  <div class="columns-settings-block">
                    <vuescroll :ops="verticalScrollParams" style="height: 300px;">
                      <draggable v-model="columns"
                                 handle=".drag-handle">
                        <div v-for="(column, i) in columns"
                             class="d-flex line-hovered"
                             :key="i">
                          <div class="flex-grow-0 pl-2 pt-1 pb-1 pr-3">
                            <v-icon class="drag-handle cursor-move">drag_indicator</v-icon>
                          </div>
                          <div class="flex-grow-1 pt-1 pb-1 cursor-pointer"
                               @click="column.is_visible = !column.is_visible">
                            {{ column.title }}
                          </div>
                          <div class="flex-grow-0 pt-1 pb-1 pl-5 pr-1">
                            <v-checkbox v-model="column.is_visible"
                                        class="mt-0"
                                        dense
                                        hide-details
                            />
                          </div>
                        </div>
                      </draggable>
                    </vuescroll>
                  </div>
                </v-menu>
              </th>
              <th v-for="(column, i) in columns"
                  v-show="column.is_visible === true"
                  :key="i">
                {{ column.title }}
              </th>
            </tr>
            </thead>
            <tbody>
            <!-- ВЫВОД СПИСКА -->
            <template v-if="!isEmptyList && err.length === 0 && !loadingSync">

              <tr v-for="(item, index) in list"
                  :class="`is-hover`"
                  :key="index">
                <td></td>
                <td v-for="(column, k) in item"
                    :key="k">

                  <div v-if="column.type === 'title'">
                    <div class="d-flex clickableCompany">
                      <div class="flex-grow-0">
                        <img :src="getLogo(column.val.logo)"
                             style="width: 24px; height: 24px; border: 1px solid rgba(0,0,0,0.12); border-radius:90px;"
                             alt=""
                        />
                      </div>
                      <div class="flex-grow-1"
                           style="margin-left: 16px;">
                        <div class="mockup2-table-label">
                          {{ column.val.title }}
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="column.type === 'responsible'">
                    <div class="d-flex">
                      <div class="flex-grow-0">
                        <img :src="getAvatar(column.val.avatar)"
                             class="card-logo-mini mr-2"
                             style="max-width: 22px; vertical-align: middle"
                             alt=""
                        />
                      </div>
                      <div class="flex-grow-1">
                        {{ column.val.first_name }} {{ column.val.last_name[0] }}.
                      </div>
                    </div>
                  </div>
                  <div v-else-if="column.type === 'created_at' || column.type === 'updated_at'">
                    {{ column.val.date }} {{ column.val.time }}
                  </div>
                  <div v-else-if="column.type === 'email'">
                    <template v-if="column.val.length > 1">
                      <v-list-group class="ml-0 pl-0 no-margin-no-padding">
                        <template v-slot:activator>
                          <v-list-item-content class="ml-0 pl-0"
                                               style="color: #344767!important;">
                            {{ column.val[0] }}
                          </v-list-item-content>
                        </template>

                        <v-list-item v-for="(email, j) in column.val.slice(1)"
                                     class="ml-0 pl-0"
                                     style="color: #344767!important;"
                                     :key="j">
                          {{ email }}
                        </v-list-item>
                      </v-list-group>
                    </template>
                    <template v-else-if="column.val.length === 1">
                      {{ column.val[0] }}
                    </template>
                    <template v-else>
                      —
                    </template>
                  </div>
                  <div v-else-if="column.type === 'phones'">
                    <template v-if="column.val.length > 1">
                      <v-list-group class="ml-0 pl-0 no-margin-no-padding">
                        <template v-slot:activator>
                          <v-list-item-content class="ml-0 pl-0"
                                               style="color: #344767!important;">
                            {{ column.val[0] }}
                          </v-list-item-content>
                        </template>

                        <v-list-item v-for="(email, j) in column.val.slice(1)"
                                     class="ml-0 pl-0"
                                     style="color: #344767!important;"
                                     :key="j">
                          {{ email }}
                        </v-list-item>
                      </v-list-group>
                    </template>
                    <div v-else-if="column.type === 'email'">
                      <template v-if="column.val.length > 1">
                        <v-list-group class="ml-0 pl-0 no-margin-no-padding">
                          <template v-slot:activator>
                            <v-list-item-content class="ml-0 pl-0"
                                                 style="color: #344767!important;">
                              {{ column.val[0] }}
                            </v-list-item-content>
                          </template>

                          <v-list-item v-for="(email, j) in column.val.slice(1)"
                                       class="ml-0 pl-0"
                                       style="color: #344767!important;"
                                       :key="j">
                            {{ email }}
                          </v-list-item>
                        </v-list-group>
                      </template>
                      <template v-else-if="column.val.length === 1">
                        {{ column.val[0] }}
                      </template>
                      <template v-else>
                        —
                      </template>
                    </div>
                    <template v-else-if="column.val.length === 1">
                      {{ column.val[0] }}
                    </template>
                    <template v-else>
                      —
                    </template>
                  </div>
                  <div v-else-if="column.type === 'addresses'">
                    <template v-if="column.val.length > 1">
                      <v-list-group class="ml-0 pl-0 no-margin-no-padding">
                        <template v-slot:activator>
                          <v-list-item-content class="ml-0 pl-0"
                                               style="color: #344767!important;">
                            {{ column.val[0] }}
                          </v-list-item-content>
                        </template>

                        <v-list-item v-for="(email, j) in column.val.slice(1)"
                                     class="ml-0 pl-0"
                                     style="color: #344767!important;"
                                     :key="j">
                          {{ email }}
                        </v-list-item>
                      </v-list-group>
                    </template>
                    <div v-else-if="column.type === 'email'">
                      <template v-if="column.val.length > 1">
                        <v-list-group class="ml-0 pl-0 no-margin-no-padding">
                          <template v-slot:activator>
                            <v-list-item-content class="ml-0 pl-0"
                                                 style="color: #344767!important;">
                              {{ column.val[0] }}
                            </v-list-item-content>
                          </template>

                          <v-list-item v-for="(email, j) in column.val.slice(1)"
                                       class="ml-0 pl-0"
                                       style="color: #344767!important;"
                                       :key="j">
                            {{ email }}
                          </v-list-item>
                        </v-list-group>
                      </template>
                      <template v-else-if="column.val.length === 1">
                        {{ column.val[0] }}
                      </template>
                      <template v-else>
                        —
                      </template>
                    </div>
                    <template v-else-if="column.val.length === 1">
                      {{ column.val[0] }}
                    </template>
                    <template v-else>
                      —
                    </template>
                  </div>
                  <div v-else-if="column.type === 'messengers'">
                    <template v-if="column.val.length > 1">
                      <v-list-group class="ml-0 pl-0 no-margin-no-padding">
                        <template v-slot:activator>
                          <v-list-item-content class="ml-0 pl-0"
                                               style="color: #344767!important;">
                            {{ column.val[0] }}
                          </v-list-item-content>
                        </template>

                        <v-list-item v-for="(email, j) in column.val.slice(1)"
                                     class="ml-0 pl-0"
                                     style="color: #344767!important;"
                                     :key="j">
                          {{ email }}
                        </v-list-item>
                      </v-list-group>
                    </template>
                    <div v-else-if="column.type === 'email'">
                      <template v-if="column.val.length > 1">
                        <v-list-group class="ml-0 pl-0 no-margin-no-padding">
                          <template v-slot:activator>
                            <v-list-item-content class="ml-0 pl-0"
                                                 style="color: #344767!important;">
                              {{ column.val[0] }}
                            </v-list-item-content>
                          </template>

                          <v-list-item v-for="(email, j) in column.val.slice(1)"
                                       class="ml-0 pl-0"
                                       style="color: #344767!important;"
                                       :key="j">
                            {{ email }}
                          </v-list-item>
                        </v-list-group>
                      </template>
                      <template v-else-if="column.val.length === 1">
                        {{ column.val[0] }}
                      </template>
                      <template v-else>
                        —
                      </template>
                    </div>
                    <template v-else-if="column.val.length === 1">
                      {{ column.val[0] }}
                    </template>
                    <template v-else>
                      —
                    </template>
                  </div>
                  <div v-else-if="column.type === 'sites'">
                    <template v-if="column.val.length > 1">
                      <v-list-group class="ml-0 pl-0 no-margin-no-padding">
                        <template v-slot:activator>
                          <v-list-item-content class="ml-0 pl-0"
                                               style="color: #344767!important;">
                            {{ column.val[0] }}
                          </v-list-item-content>
                        </template>

                        <v-list-item v-for="(email, j) in column.val.slice(1)"
                                     class="ml-0 pl-0"
                                     style="color: #344767!important;"
                                     :key="j">
                          {{ email }}
                        </v-list-item>
                      </v-list-group>
                    </template>
                    <div v-else-if="column.type === 'email'">
                      <template v-if="column.val.length > 1">
                        <v-list-group class="ml-0 pl-0 no-margin-no-padding">
                          <template v-slot:activator>
                            <v-list-item-content class="ml-0 pl-0"
                                                 style="color: #344767!important;">
                              {{ column.val[0] }}
                            </v-list-item-content>
                          </template>

                          <v-list-item v-for="(email, j) in column.val.slice(1)"
                                       class="ml-0 pl-0"
                                       style="color: #344767!important;"
                                       :key="j">
                            {{ email }}
                          </v-list-item>
                        </v-list-group>
                      </template>
                      <template v-else-if="column.val.length === 1">
                        {{ column.val[0] }}
                      </template>
                      <template v-else>
                        —
                      </template>
                    </div>
                    <template v-else-if="column.val.length === 1">
                      {{ column.val[0] }}
                    </template>
                    <template v-else>
                      —
                    </template>
                  </div>
                  <div v-else-if="column.type === 'watchers'">
                    <template v-if="column.val.length > 1">
                      <v-list-group class="ml-0 pl-0 no-margin-no-padding">
                        <template v-slot:activator>
                          <v-list-item-content class="ml-0 pl-0"
                                               style="color: #344767!important;">
                            <div class="d-flex">
                              <div class="flex-grow-0">
                                <img :src="getAvatar(column.val[0].avatar)"
                                     class="card-logo-mini mr-2"
                                     style="max-width: 22px; vertical-align: middle"
                                     alt=""
                                />
                              </div>
                              <div class="flex-grow-1">
                                {{ column.val[0].first_name }} {{ column.val[0].last_name[0] }}.
                              </div>
                            </div>
                          </v-list-item-content>
                        </template>

                        <v-list-item v-for="(user, j) in column.val.slice(1)"
                                     class="ml-0 pl-0"
                                     style="color: #344767!important;"
                                     :key="j">
                          <div class="d-flex">
                            <div class="flex-grow-0">
                              <img :src="getAvatar(user.avatar)"
                                   class="card-logo-mini mr-2"
                                   style="max-width: 22px; vertical-align: middle"
                                   alt=""
                              />
                            </div>
                            <div class="flex-grow-1">
                              {{ user.first_name }} {{ user.last_name[0] }}.
                            </div>
                          </div>
                        </v-list-item>
                      </v-list-group>
                    </template>
                    <template v-else-if="column.val.length === 1">
                      {{ column.val[0] }}
                    </template>
                    <template v-else>
                      —
                    </template>
                  </div>
                  <div v-else>
                    <div v-if="column.val && column.val.length > 0">
                      {{ column.val }}
                    </div>
                    <div v-else>
                      —
                    </div>

                  </div>
                </td>
              </tr>
            </template>

            <!-- НЕТ ДАННЫХ -->
            <tr v-if="isEmptyList && !loadingData">
              <td :colspan="columns.length">
                <div class="text-center">
                  <p class="mt-2 mb-2">
                    Контрагенты не найдены
                  </p>
                </div>
              </td>
            </tr>

            <!-- ЛОАДЕР -->
            <tr v-if="(isEmptyList && loadingData) || loadingSync">
              <td :colspan="columns.length" class="text-center pt-2 pb-2">
                <VProgressLinear indeterminate/>
              </td>
            </tr>

            <!-- ОШИБКА ВЫВОДА -->
            <tr v-if="isErr">
              <td :colspan="columns.length">
                <v-alert color="error">{{ err }}</v-alert>
              </td>
            </tr>
            </tbody>
            <tfoot v-if="isShowFooter">
            <tr>
              <th class="td-min-width pa-1">
                <v-menu right bottom
                        offset-y
                        class=""
                        :nudge-bottom="10"
                        :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs"
                            v-on="on"
                            small>settings
                    </v-icon>
                  </template>
                  <div class="columns-settings-block">
                    <vuescroll :ops="verticalScrollParams" style="height: 300px;">
                      <draggable v-model="columns"
                                 handle=".drag-handle">
                        <div v-for="(column, i) in columns"
                             class="d-flex line-hovered"
                             :key="i">
                          <div class="flex-grow-0 pl-2 pt-1 pb-1 pr-3">
                            <v-icon class="drag-handle cursor-move">drag_indicator</v-icon>
                          </div>
                          <div class="flex-grow-1 pt-1 pb-1 cursor-pointer"
                               @click="column.is_visible = !column.is_visible">
                            {{ column.title }}
                          </div>
                          <div class="flex-grow-0 pt-1 pb-1 pl-5 pr-1">
                            <v-checkbox v-model="column.is_visible"
                                        class="mt-0"
                                        dense
                                        hide-details
                            />
                          </div>
                        </div>
                      </draggable>
                    </vuescroll>
                  </div>
                </v-menu>
              </th>
              <th v-for="(column, i) in columns"
                  v-show="column.is_visible === true"
                  :key="i">
                {{ column.title }}
              </th>
            </tr>
            </tfoot>
          </table>

        </vuescroll>
      </v-card>
    </template>

    <!-- ПРЕЛОАДЕР ЗАГРУЗКИ СТРАНИЦЫ -->
    <VProgressLinear
        v-if="preloader"
        indeterminate
        color="primary"
        class="mt-6"
    />

    <!-- ОТОБРАЖЕНИЕ ГЛОБАЛЬНОЙ ОШИБКИ -->
    <v-alert v-if="err.length > 0"
             type="error"
             text>
      <div v-html="err"></div>
    </v-alert>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import vuescroll from 'vuescroll';

export default {
  name: "ClientsPage",
  components: {
    vuescroll,
    draggable
  },
  data: () => ({
    // Свойства
    access: [],
    settings: [],
    columns: [],
    list: [],
    // Загрузка основных данных
    loading: true,
    preloader: false,
    preloaderTimer: null,
    // Конфиги
    loadingConfig: true,
    loadingSync: false,
    loadingData: false,
    // Технические свойства
    err: '',
    // Скролл
    scrollParams: {
      vuescroll: {
        checkShiftKey: true,
        locking: false
      },
      scrollButton: {
        enable: true,
        background: 'rgba(0, 0, 0, 0.4)',
        opacity: 0,
        step: 180,
        mousedownStep: 30
      },
      scrollPanel: {
        scrollingX: true,
        scrollingY: false,
      },
      bar: {
        onlyShowBarOnScroll: false,
        keepShow: false,
        opacity: '0.5',
        background: 'rgba(0, 0, 0, 0.3)'
      }
    },
    // Вертикальный скролл
    verticalScrollParams: {
      vuescroll: {
        checkShiftKey: true,
        locking: false
      },
      scrollButton: {
        enable: true,
        background: 'rgba(0, 0, 0, 0.4)',
        opacity: 0,
        step: 180,
        mousedownStep: 30
      },
      scrollPanel: {
        scrollingX: false,
        scrollingY: true,
      },
      bar: {
        onlyShowBarOnScroll: false,
        keepShow: false,
        opacity: '0.5',
        background: 'rgba(0, 0, 0, 0.3)'
      }
    },
  }),
  created() {
    this.getBasicInfo();
  },
  computed: {
    isEmptyList() {
      return (this.err.length === 0 && this.list.length === 0);
    },
    isErr() {
      return this.err.length > 0;
    },
    isShowFooter() {
      const appSettings = this.$store.state.settings;

      return 'clients_table_list_is_show_footer' in appSettings && appSettings.clients_table_list_is_show_footer === '1';
    }
  },
  watch: {
    columns: {
      handler(newValue, oldValue) {

        if (oldValue.length === 0) {
          this.getList();
        } else {
          this.syncColumns();
        }
      },
      deep: true
    }
  },
  methods: {
    getBasicInfo() {
      this.err = '';
      this.setPreloaderTimer(true);
      this.$http.get('/api/clients')
          .then((res) => {
            const data = res.data;
            this.access = data.access;
            this.settings = data.settings;
            this.getConfig();
          })
          .catch((e) => {
            this.err = this.$formatter.getErr(e);
          })
          .finally(() => {
            this.loading = false;
            this.setPreloaderTimer(false);
          });
    },
    getConfig() {
      this.loadingConfig = true;
      this.$http.get('/api/clients/config')
          .then((res) => {
            this.columns = res.data.columns;
            this.getList();
          })
          .catch((e) => {
            this.err = this.$formatter.getErr(e);
          })
          .finally(() => {
            this.loadingConfig = false;
          });
    },
    syncColumns() {
      this.loadingSync = true;
      const data = JSON.parse(JSON.stringify(this.columns));
      this.$http.post('/api/clients/config', {columns: data})
          .then(() => {
            this.getList();
          })
          .catch((e) => {
            this.loadingSync = false;
            this.err = this.$formatter.getErr(e);
          });
    },
    getList() {
      this.loadingData = true;
      this.$http.get('/api/clients/items')
          .then((res) => {
            this.list = res.data.list;
          })
          .catch((e) => {
            this.err = this.$formatter.getErr(e);
          })
          .finally(() => {
            this.loadingSync = false;
            this.loadingData = false;
          });
    },
    setPreloaderTimer(isActive) {
      const self = this;

      if (this.preloaderTimer !== null) {
        clearInterval(this.preloaderTimer);
        this.preloaderTimer = null;
      }

      if (isActive) {
        this.preloaderTimer = setTimeout(function () {
          self.preloader = true;
        }, this.$settings.PAGE_PRELOADER_DURATION);
      } else {
        this.preloader = false;
      }
    }
  }
}
</script>