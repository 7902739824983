<template>
  <v-app-bar dark app :class="`control-panel top-panel ${hidden}`">

    <!-- БУРГЕР ЛЕВОГО МЕНЮ-->
    <v-btn icon @click="switchLeftMenu">
      <v-icon>menu</v-icon>
    </v-btn>

    <!-- ЗАГОЛОВОК ВЕРХНЕЙ ПАНЕЛИ -->
    <v-card-title class="header-title text-no-wrap">
      <img src="/img/h-logo.png"
           alt=""
           class="mr-3"
           style="max-width: 32px;"/>
      <span class="d-block" style="margin-top: 3px;">HYBROTECH SYSTEMS</span>
    </v-card-title>

    <v-spacer></v-spacer>

    <v-menu right bottom class="menu-right">
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex right-button"
             v-bind="attrs"
             v-on="on">
          <div class="flex-grow-0 pe-2 d-flex align-self-center align-items-center">
            <img :src="getAvatar"
                 class="avatar"
                 alt=""
            />
          </div>
          <div class="flex-grow-1 align-self-center">
            <div class="user-label">{{ userLabel }}</div>
            <div class="user-group warning--text">{{ userGroup }}</div>
          </div>
          <div class="flex-grow-0 ps-1 align-self-center">
            <v-icon>expand_more</v-icon>
          </div>
        </div>
      </template>
      <v-list :class="`top-right-popup`" dense>
        <!--v-list-item @click="$router.push('/profile')">
          <v-list-item-title class="white--text">
            <v-icon class="white--text pr-2">person</v-icon>
            Мой профиль
          </v-list-item-title>
        </v-list-item-->
        <v-list-item @click="logout">
          <v-list-item-title class="white--text">
            <v-icon class="white--text pr-2">logout</v-icon>
            {{ $t.get('LOGOUT') }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

  </v-app-bar>
</template>

<script>
export default {
  name: "TopPanel",
  data: () => ({
    hidden: 'hidden',
  }),
  computed: {
    getAvatar() {
      const avatar = this.$store.state.user.avatar;

      if (!avatar || typeof avatar !== 'string' || avatar.length === 0) {
        return '/img/users/default-avatar.png';
      }

      return `/img/users/avatars/${avatar}`;
    },
    userLabel() {
      const user = this.$store.state.user;

      return `${user.first_name} ${user.last_name[0]}.`;
    },
    userGroup() {
      return 'Администратор';
    }
  },
  mounted() {
    const self = this;
    setTimeout(function () {
      self.hidden = '';
    }, 100);
  },
  methods: {
    switchLeftMenu() {
      this.$store.commit('switchLeftMenuState');
    },
    logout() {
      const url = '/api/auth/logout';
      const params = {refresh_token: this.$store.state.user.refreshToken};
      this.$http.post(url, params)
          .then(() => {
            this.$store.state.app.preloader.isShowPanel = true;
            const self = this;
            setTimeout(function () {
              self.$store.state.app.loading = true;
              self.$store.commit("setAuthState", {
                isAuthorized: false,
                accessToken: null,
                refreshToken: null
              });
              self.$store.dispatch('initAuth');
            }, 500);
          })
          .catch((e) => {
            console.log(e);
          });
    },
  }
}
</script>
