<template>
  <div>

    <template v-if="!loading && err.length === 0">

      <!-- ЗАГОЛОВОК СТРАНИЦЫ -->
      <div class="page-title">
        <div class="d-flex">
          <div class="flex-grow-1 align-self-end">
            <h2>
              <v-icon>tune</v-icon>
              Настройки
              <v-icon small class="arrow">chevron_right</v-icon>
              Система
            </h2>
          </div>
          <div class="flex-grow-0 align-self-center mr-3"></div>
        </div>
        <v-divider class="mt-1 mb-3"></v-divider>
      </div>

      <!-- ТАБЛИЦА-СПИСОК С ДАННЫМИ -->
      <v-card class="pa-4">
        <v-tabs v-model="tab"
                class="font-weight-light"
                color="hcolor">
          <v-tab v-for="(item) in tabs"
                 class="font-weight-regular"
                 style="text-transform: none; color: #344767"
                 :key="item.hash">
            <v-icon class="mr-1">{{ item.icon }}</v-icon>
            {{ item.title }}
          </v-tab>

          <v-tabs-items v-model="tab">

            <!-- НАСТРОЙКИ ПАНЕЛИ УПРАВЛЕНИЯ-->
            <v-tab-item>
              <ControlPanel/>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-card>
    </template>

    <!-- ПРЕЛОАДЕР ЗАГРУЗКИ СТРАНИЦЫ -->
    <VProgressLinear
        v-if="preloader"
        indeterminate
        color="primary"
        class="mt-6"
    />

    <!-- ОТОБРАЖЕНИЕ ГЛОБАЛЬНОЙ ОШИБКИ -->
    <v-alert v-if="err.length > 0"
             type="error"
             text>
      <div v-html="err"></div>
    </v-alert>
  </div>
</template>

<script>
import ControlPanel from "@/components/pages/settings/system/ControlPanel";

export default {
  name: "SystemPage",
  components: {ControlPanel},
  data: () => ({
    // Свойства
    access: [],
    settings: {},
    // Табы
    tab: 0,
    tabs: [
      {
        icon: 'apps',
        title: 'Панель управления',
        hash: ''
      },
    ],
    // Загрузка основных данных
    loading: true,
    preloader: false,
    preloaderTimer: null,
    // Технические свойства
    err: '',
  }),
  created() {
    this.getBasicInfo();
  },
  methods: {
    getBasicInfo() {
      this.err = '';
      this.setPreloaderTimer(true);
      this.$http.get('/api/settings/system')
          .then((res) => {
            const data = res.data;
            this.access = data.access;
            this.settings = data.settings;
          })
          .catch((e) => {
            this.err = this.$formatter.getErr(e);
          })
          .finally(() => {
            this.loading = false;
            this.setPreloaderTimer(false);
          });
    },
    setPreloaderTimer(isActive) {
      const self = this;

      if (this.preloaderTimer !== null) {
        clearInterval(this.preloaderTimer);
        this.preloaderTimer = null;
      }

      if (isActive) {
        this.preloaderTimer = setTimeout(function () {
          self.preloader = true;
        }, this.$settings.PAGE_PRELOADER_DURATION);
      } else {
        this.preloader = false;
      }
    }
  }
}
</script>