export default {
    LANG_DEFAULT: 'en-US',
    LANGS_SUPPORTED: [
        'en-US',
        'ru-RU',
    ],
    LANGS_DOM_MATCHES: {
        'en-US': 'en',
        'ru-RU': 'ru',
    },
    LANGS_LABELS: {
        'en-US': 'English',
        'ru-RU': 'Русский'
    },
    APP_PRELOADER_DURATION: 500,
    PAGE_PRELOADER_DURATION: 500,
};
