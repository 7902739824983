import Vue from 'vue';
import Vuex from 'vuex';
import cookies from "@/plugins/cookies";
import settings from "@/plugins/settings";
import InitSystem from "@/plugins/init/InitSystem";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        app: {
            preloader: {
                isShow: true,
                isShowProgress: false,
                timer: null,
            },
            hosts: {
                navigate: {
                    protocol: '',
                    hostname: '',
                    port: '',
                },
                sector: {
                    protocol: '',
                    hostname: '',
                    port: '',
                }
            },
            lang: {
                forced: '',
                host: '',
                browser: '',
                default: settings.LANG_DEFAULT,
            },
            loading: true,
            err: '',
        },
        user: {
            accessToken: null,
            refreshToken: null,
            isAuthorized: false,
            //
            id: 0,
            last_name: '',
            first_name: '',
            second_name: '',
            avatar: '',
            username: '',
        },
        auth: {
            title: {
                isCustomLogo: false,
                isCustomLabel: false,
                isCustomBgImage: false,
                customLogo: '',
                customLabel: '',
                customBgImage: '',
            }
        },
        panel: {
            isLoading: true,
            isLeftMenuOpen: true,
            title: {
                logo: '',
                label: '',
                backgroundImage: '',
                rightBadges: [],
                rightMenu: [],
            },
            leftMenu: [],
            successNotice: {
                isOpen: false,
                label: ''
            },
        },
        settings: {},
    },
    mutations: {
        // Устанавливаем статус авторизации
        setAuthState(state, data) {
            const accessToken = data.accessToken;
            const refreshToken = data.refreshToken;
            const isAuthorized = data.isAuthorized;
            state.user.accessToken = accessToken;
            state.user.refreshToken = refreshToken;
            state.user.isAuthorized = isAuthorized;
        },
        switchLeftMenuState(state) {
            state.panel.isLeftMenuOpen = !state.panel.isLeftMenuOpen;

            // Если нужно писать куку
            if (
                'cp_left_panel_save_state' in state.settings
                && state.settings.cp_left_panel_save_state === 'cookies'
            ) {
                let cookieVal = '0';

                if (state.panel.isLeftMenuOpen === true) {
                    cookieVal = '1';
                }
                cookies.setCookiePersistent('cp_left_panel_init_is_short', cookieVal);
            }
        },
        setHost(state, data) {
            state.app.hosts[data.key] = {
                protocol: data.protocol,
                hostname: data.hostname,
                port: data.port,
            };
        },
        setLang(state, data) {
            state.app.lang[data.key] = data.lang;
        },
        setAppLoading(state, isLoading = false) {
            state.app.loading = isLoading;
        },
        setAppPreloaderTimer(state, isActive = false) {

            // Обнуляем таймер, если он был задан
            if (state.app.preloader.timer !== null) {
                clearTimeout(state.app.preloader.timer);
                state.app.preloader.timer = null;
            }

            if (isActive) {
                state.app.preloader.isShow = true;
                state.app.preloader.timer = setTimeout(function () {
                    // Если за заданное время инициализация системы не завершилась - отображаем прелоадер
                    state.app.preloader.isShowProgress = true;
                }, settings.APP_PRELOADER_DURATION);

                return;
            }
            state.app.preloader.isShow = false;
            state.app.preloader.isShowProgress = false;
        },
        setAppErr(state, e) {

            if (typeof e === 'object') {

                // Ошибка запроса
                if ('response' in e && e.response) {

                    if ('data' in e.response && e.response.data) {
                        state.app.err = e.response.data;

                        return;
                    }
                }

                if ('message' in e && e.message) {
                    state.app.err = e.message;

                    return;
                }
                state.app.err = JSON.stringify(e);
            } else {
                state.app.err = e;
            }
        },
        // Отображаем плашку сохранения
        showSaveNotice(state, label = '') {
            state.panel.successNotice.label = label;
            state.panel.successNotice.isOpen = true;
        },
        showNoticeHidden(state) {
            state.panel.successNotice.isOpen = false;
            state.panel.successNotice.label = '';
        }
    },
    actions: {
        initSystem(ctx) {
            InitSystem.handle(ctx);
        },
        setLang(ctx, lang) {
            cookies.setCookiePersistent('lang', lang);
            InitSystem.reInit(ctx);
        },
        setAuthUser(ctx, data) {
            const accessToken = data.accessToken;
            const refreshToken = data.refreshToken;
            const rememberMe = Boolean(data.rememberMe);
            let cookieOptions = {};

            if (rememberMe) {
                let d = new Date();
                d.setTime(d.getTime() + (365*24*60*60*1000));
                cookieOptions.expires = d;
            }
            cookies.setCookie('accessToken', accessToken, cookieOptions);
            cookies.setCookie('refreshToken', refreshToken, cookieOptions);

            ctx.commit('setAuthState', {
                accessToken: accessToken,
                refreshToken: refreshToken,
                isAuthorized: true,
            });
        },
        initPanel(ctx) {
            InitSystem.reInitAuth(ctx);
        },
        initAuth(ctx) {
            InitSystem.reInitAuth(ctx);
        },
    },
    getters: {
        // Загрузка приложения
        isAppLoading(state) {
            return state.app.loading;
        },
        // Прелоадер приложения
        getAppPreloaderIsShow(state) {
            return state.app.preloader.isShow;
        },
        getAppPreloaderIsShowProgress(state) {
            return state.app.preloader.isShowProgress;
        },
        // Язык приложения
        getLang(state) {

            // Если язык задан на уровне куки
            if (state.app.lang.forced !== '') {
                return state.app.lang.forced;
            }

            // Если язык задан на уровне платформы
            if (state.app.lang.host !== '') {
                return state.app.lang.host;
            }

            // Если определился язык браузера и он поддерживается системой
            if (state.app.lang.browser !== '') {
                return state.app.lang.browser;
            }

            // Во всех остальных случаях возвращаем изначальный язык по умолчанию
            return state.app.lang.default;
        },
        // Получить ошибку приложения
        getAppErr(state) {
            return state.app.err;
        },
        // Access токен
        userAccessToken(state) {
            return state.user.accessToken;
        },
        isUserAuthorised(state) {
            return state.user.isAuthorized;
        },
        isLeftMenuOpen(state) {
            return state.panel.isLeftMenuOpen;
        },
        getPanelLeftMenu(state) {
            return state.panel.leftMenu;
        },
        getNavigateServerUrl(state) {
            const navigate = state.app.hosts.navigate;
            let url = `${navigate.protocol}//${navigate.hostname}`;

            if (['80', '443'].indexOf(String(navigate.port)) === -1) {
                url += `:${navigate.port}`;
            }

            return url;
        },
        // Успешные уведомления
        getSuccessNoticeState(state) {
            return state.panel.successNotice.isOpen;
        },
        getSuccessNoticeLabel(state) {
            return state.panel.successNotice.label;
        },
    }
});