<template>
  <v-main class="outer-panel">

    <v-app-bar fixed dark app :class="`header-block ${hidden}`">
      <v-card-title class="header-title">
        <img src="/img/h-logo.png"
             alt=""
             class="mr-3"
             style="max-width: 32px;"/>
        <span class="d-block" style="margin-top: 3px;">HYBROTECH SYSTEMS</span>
        <v-spacer></v-spacer>
        <v-menu right bottom class="menu-right">
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex"
                 v-bind="attrs"
                 v-on="on">
              <img :src="getLang()"
                   style="max-width: 24px;"
                   alt="Русский"
              />
              <v-icon class="ml-1">expand_more</v-icon>
            </div>
          </template>
          <v-list dense>
            <v-list-item v-for="(langItem, k) in langsSupported"
                         :disabled="langItem.code === currentLang"
                         @click="setLang(langItem.code)"
                         :key="k">
              <img :src="`/img/lang/${langItem.val}.png`"
                   style="width: 24px;"
                   :alt="langItem.val"
              />
              <div class="ml-3">{{ getLangLabel(langItem.code) }}</div>
              <div v-if="langItem.code === currentLang">
                <v-icon>chevron_left</v-icon>
              </div>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
    </v-app-bar>

    <v-container class="h-100 pl-3 pl-md-5 pr-3 pr-md-5">
      <v-layout class="align-center justify-center h-100">
        <v-flex xs12 md7 lg5>
          <v-card :class="`border-radius-xl d-flex flex-column login-form-block ${hidden}`">

            <div class="card-padding text-center mt-n7 bg-gradient-neutral shadow-dark border-radius-lg mx-4">
              <h5 class="text-h5 white--text font-weight-medium">{{ $t.get('AUTHORIZATION') }}</h5>
            </div>

            <div class="card-padding mt-2">

              <v-text-field label="E-mail"
                            v-model="fields.username.val"
                            :rules="[fields.username.rules.required]"
                            :error-messages="fields.username.err"
                            :disabled="isDisabledField"
                            prepend-inner-icon="person"
                            outlined
                            dense
                            @keyup.enter="signIn"
              />

              <v-text-field :label="$t.get('PASS')"
                            v-model="fields.pass.val"
                            :disabled="isDisabledField"
                            :rules="[fields.pass.rules.required, fields.pass.rules.min]"
                            :error-messages="fields.pass.err"
                            :type="fields.pass.show ? 'text' : 'password'"
                            :append-icon="fields.pass.show ? 'visibility' : 'visibility_off'"
                            @click:append="fields.pass.show = !fields.pass.show"
                            prepend-inner-icon="lock"
                            :hint="$t.get('MINIMUM_8_CHARACTERS')"
                            counter
                            outlined
                            dense
                            @keyup.enter="signIn"
              />

              <v-switch v-model="rememberMe"
                        color="primary"
                        :label="$t.get('REMEMBER_ME')"
                        :disabled="isSigning"
                        hide-details
                        class="mt-0 pt-0"
              />

              <v-alert v-if="success.length" type="success" class="mt-5">
                {{ $t.get('LABEL_AUTH_SUCCESS') }}
              </v-alert>

              <v-btn block
                     :disabled="isSigning"
                     :loading="isSigning"
                     class="btn bg-gradient-info mt-5"
                     @click="signIn">
                {{ $t.get('SIGN_IN') }}
              </v-btn>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-footer fixed :class="`footer-block ${hidden} footer-copyright w-100 d-block`">
      <p class="text-center mb-0">
        © {{ (new Date().getFullYear()) }} {{ $t.get('FOOTER_DEFAULT_COPYRIGHT') }}
      </p>
    </v-footer>
  </v-main>
</template>

<script>
export default {
  name: "LoginPanel",
  data() {
    const LABEL_FIELD_IS_REQUIRED = this.$t.get('LABEL_FIELD_IS_REQUIRED');
    const MINIMUM_8_CHARACTERS = this.$t.get('MINIMUM_8_CHARACTERS');

    return {
      fields: {
        username: {
          val: '',
          err: '',
          rules: {
            required: value => !!value || LABEL_FIELD_IS_REQUIRED,
          }
        },
        pass: {
          val: '',
          err: '',
          show: false,
          rules: {
            required: value => !!value || LABEL_FIELD_IS_REQUIRED,
            min: v => v.length >= 8 || MINIMUM_8_CHARACTERS,
          },
        },
      },
      isSigning: false,
      rememberMe: true,
      success: '',
      err: '',
      hidden: 'hidden',
    }
  },
  created() {
    this.$page.setTitle(this.$t.get('AUTHORIZATION'));
    this.$store.state.app.preloader.isShowPanel = false;
  },
  mounted() {
    const self = this;
    setTimeout(function () {
      self.hidden = '';
    }, 300);
  },
  computed: {
    isDisabledField() {
      return (this.isSigning || this.success.length > 0);
    },
    currentLang() {
      return this.$store.getters.getLang;
    },
    langsSupported() {
      const langsMatches = this.$settings.LANGS_DOM_MATCHES;
      let result = [];

      for (let key in langsMatches) {
        result.push({
          code: key,
          val: langsMatches[key],
        });
      }

      return result;
    }
  },
  watch: {
    'fields.username.val'() {
      this.fields.username.err = '';
    },
    'fields.pass.val'() {
      this.fields.pass.err = '';
    },
  },
  methods: {
    signIn() {
      this.clearGlobalMsgs();

      if (!this.validateFields()) {
        return;
      }
      this.isSigning = true;
      this.sendSignRequest();
    },
    sendSignRequest() {
      const url = '/api/auth/login';
      const params = {
        username: this.fields.username.val,
        pass: this.fields.pass.val,
      };
      this.$http.post(url, params)
          .then((res) => {
            this.success = this.$t.get('LABEL_AUTH_SUCCESS');
            const self = this;
            setTimeout(function () {
              self.$store.state.app.preloader.isShowPanel = true;
              setTimeout(function () {
                self.$store.state.app.loading = true;
                self.$store.dispatch('setAuthUser', {
                  accessToken: res.data.access_token,
                  refreshToken: res.data.refresh_token,
                  rememberMe: self.rememberMe,
                });
                self.$store.dispatch('initPanel');
              }, 500);
            }, 1000);
          })
          .catch((e) => {

            if ('data' in e.response) {
              this.fields.pass.err = e.response.data;
            }
          })
          .finally(() => {
            this.isSigning = false;
          });
    },
    validateFields() {
      let fields = this.fields;
      let result = true;

      if (!this.validateField(fields.username)) {
        result = false;
      }

      if (!this.validateField(fields.pass)) {
        result = false;
      }

      return result;
    },
    validateField(field) {
      let err = false;
      field.err = '';

      for (let key in field.rules) {

        if (Object.prototype.hasOwnProperty.call(field.rules, key)) {
          err = field.rules[key](field.val);

          if (typeof err === 'string' && err.length > 0) {
            field.err = err;

            return false;
          }
        }
      }

      return true;
    },
    clearGlobalMsgs() {
      this.success = '';
      this.err = '';
    },
    getLang(lang = '') {

      if (lang === '') {
        lang = this.currentLang;
      }
      const langsDomMatches = this.$settings.LANGS_DOM_MATCHES;

      if (lang in langsDomMatches) {
        return `/img/lang/${langsDomMatches[lang]}.png`;
      }

      return '';
    },
    getLangLabel(code) {
      const langsLabels = this.$settings.LANGS_LABELS;

      if (code in langsLabels) {
        return langsLabels[code];
      }

      return ' - ';
    },
    setLang(code) {
      this.$store.dispatch('setLang', code);
    }
  }
}
</script>
